*{
  padding: 0;
  margin: 0;
  color: white;
  font-family: 'Roboto', sans-serif;
}

body{
  background: #131210;
}

.App{
  display: grid;
  gap: 30px;
  padding-bottom: 30px;
}

#Header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #252422;
}

#Menu{
  display: flex;
  gap: 20px;
  position: absolute;
  left: calc(50% - 110px);
}

#HeaderRight{
  display: flex;
  align-items: center;
  gap: 10px;
}

#HeaderRight img{
  width: 28px;
}

#MintPage{
  display: grid;
  justify-self: center;
  width: fit-content;
}

#MintCard{
  background: #252422;
  display: grid;
  gap: 20px;
  justify-items: center;
  padding: 30px;
  border-radius: 10px;
}

#CardGif{
  width: 300px;
  border-radius: 10px;
}

#CardTotal{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#MintCard button{
  width: 100%;
}

#MintAmountInput{
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  text-align: center;
  align-items: center;
  background: #131210;
  padding: 10px;
  border-radius: 10px;
}

#MintAmountInput button{
  background: #252422;
  color: white;
}

#Marketplace, #Inventory{
  display: grid;
  gap: 30px;
  width: fit-content;
  justify-self: center;

}

#MarketplaceContainer, #InventoryContainer{
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: legacy center;
  gap: 30px;
  -ms-flex-pack: start;
}

#Inventory{
  width: 80%;
  justify-self: center;
}

#InventoryButtons{
  background: #504844;
  width: fit-content;
  border-radius: 10px;
}

#InventoryButtons button{
  background: none;
}

#UserInventoryCard, #MarketplaceCard{
  display: grid;
  text-align: center;
  width: 280px;
  gap: 20px;
  border-radius: 10px;
  background: #504844;
  padding: 20px;
  font-size: 13px;
}

#UserInventoryCard img, #MarketplaceCard img{
  border-radius: 10px;
  width: 280px;
  justify-self: center;
}

button{
  font-family: 'Roboto', sans-serif;
  background: #ffde73;
  border: none;
  padding: 10px;
  border-radius: 10px;
  color: #252422;
}

button:hover{
  cursor: pointer;
  filter: saturate(130%);
}

a{
  text-decoration: none;
  color: white;
}

button:disabled{
  background: gray;
}

button:disabled:hover{
  cursor:not-allowed;
}

.Seperator{
  height: 1px;
  width: 100%;
  border: none;
  background-color: white;
}

#MobileMenuRight{
  display: none;
}

#MobileMenu{
  display: none;
}

@media only screen and (max-width: 1440px) {
  #MarketplaceContainer, #InventoryContainer{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: legacy center;
    gap: 30px;
    -ms-flex-pack: start;
  }
  #UserInventoryCard, #MarketplaceCard{
    display: grid;
    text-align: center;
    width: 250px;
    gap: 20px;
    border-radius: 10px;
    background: #504844;
    padding: 20px;
    font-size: 13px;
  }
  
  #UserInventoryCard img, #MarketplaceCard img{
    border-radius: 10px;
    width: 250px;
    justify-self: center;
  }
}

@media only screen and (max-width: 500px) {
  #MobileMenu{
    position: fixed;
    background: #504844;
    border-right: 2px solid #ffffff;
    left: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    display: none;
    padding: 30px;
  }
  #MobileMenu button{
    width: 50px;
    height: 50px;
    justify-self: end;
  }
  #MobileMenuItems{
    display: flex;
    flex-direction: column;
    gap: 50px;
    font-size: larger;
    text-align: center;
  }
  #MobileMenuSocials{
    display: flex;
    justify-content: space-evenly;
  }
  #MobileMenuRight{
    display: flex;
    gap: 10px;
  }
  #Menu, #HeaderRight{
    display: none;
  }
  #MarketplaceContainer, #InventoryContainer{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-items: legacy center;
    gap: 30px;
    -ms-flex-pack: start;
  }
}

